import { CssTheme, ButtonTextConfig } from 'website/components/types'
import DealerInfoThemes from './DealerInfo'
import FooterInfoThemes from './FooterInfo'
import ScheduleThemes from './Schedule'
import SocialsThemes from './Socials'
import DealerLocationsPopoverThemes from './DealerLocationsPopover'
import VehiclesFilterThemes from './VehiclesFilter'
import VehiclesSortingThemes from './VehiclesSorting'
import VehiclesFilterChipsThemes from './VehiclesFilterChips'
import VehicleCardThemes from './VehicleCard'
import VehicleCardSkeletonThemes from './VehicleCardSkeleton'
import InventoryPageThemes from './InventoryPage'
import VehicleBriefInfo from './VehicleBriefInfo'
import Header from './Header'
import PrequalifiedFormSSNIncome from './PreqialifiedFormSSNIcome'
import InfoLink from './InfoLink'
import UnsubscribePage from './UnsubscribePage'
import UnsubscribedPage from './UnsubscribedPage'
import DealershipLocationInfo from './DealershipLocationInfo'
import DealershipLocations from './DealershipLocations'
import DealerLocationsMenu from './DealerLocationsMenu'
import HomePage from './HomePage'

/**
 * DD-TODO:
 * - split theme file into multiple files
 * - add global common style presets as 'horizontallyScrollable' and 'defaultButtonTextConfig'
 * and reuse it where those are duplicated
 */

const typographyTheme = {
  default: `
font-family: Roboto, sans-serif;
font-weight: 400;
line-height: 1.5;
font-size: 16px;
`,

  body2: `
font-size: 14px;

@media (min-width: 376px) {
  font-size: 16px;
}
`,
  smallText: `
  font-size: 12px;
`,
  textMain: `
font-size: 12px;

@media (min-width: 767px) {
  font-size: 14px;
}

@media (min-width: 1440px) {
  font-size: 16px;
}
`,

  textInfo: `
font-size: 14px;

@media (min-width: 1200px) {
  font-size: 20px;
}
`,

  text1: `
font-size: 14px;
`,

  text2: `
font-size: 16px;

@media (min-width: 767px) {
  font-size: 18px;
}
`,

  subtitle1: `
font-size: 16px;
font-weight: 700;

@media (min-width: 767px) {
  font-size: 18px;
}
`,
  subtitle1Thin: `
font-size: 14px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 24px;
}
`,

  h1: `
font-family: Montserrat;
font-size: 32px;
font-weight: 700;

@media (min-width: 767px) {
  font-size: 40px;
}
`,
  header1Thin: `
font-size: 34px;
line-height: 36px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
}`,

  header1: `
font-size: 34px;
line-height: 36px;
font-weight: 400;

@media (min-width: 767px) {
  font-size: 44px;
  line-height: 56px;
  font-weight: 700;
}
`,

  h2: `
font-size: 18px;
font-weight: 700;

@media (min-width: 376px) {
  font-size: 20px;
}

@media (min-width: 1440px) {
  font-size: 24px;
}
`,
  h3: `
  font-family: Montserrat;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
`,
  h4: `
  font-size: 16px;

  @media (min-width: 1200px) {
    font-size: 20px;
    font-weight: 500;
  }
`,
  h4Thin: `
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
  `,
  header4: `
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`,
  body1: `
font-size: 16px;

@media (min-width: 767px) {
  font-size: 20px;
}
`,
  noCarInfoTitle: `
  font-size: 34px;
  line-height: 36px;
`
}

const horizontallyScrollable = `
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const defaultButtonTextConfig: Required<ButtonTextConfig> = {
  color: 'inherit',
  textAlign: 'left',
  textDecorationStyle: 'none',
  fontWeight: 400
}

const ButtonText = ({
  textAlign,
  textDecorationStyle,
  fontWeight,
  color
}: ButtonTextConfig): string => `
  & .MuiButton-root {
    padding: 0;
    margin: 0;
    text-transform: none;
    color: ${color ?? defaultButtonTextConfig.color};
    font-weight: ${fontWeight ?? defaultButtonTextConfig.fontWeight};
    text-decoration: ${textDecorationStyle ?? defaultButtonTextConfig.textDecorationStyle};
    background-color: transparent;

    ${typographyTheme.body2}

    &:hover {
      background-color: transparent;
    }

    & > span {
      display: inline-block;
      text-align: ${textAlign ?? defaultButtonTextConfig.textAlign};
    }
  }
`

/**
 * DD-NOTE: as we migrate from JSS style engine and yet do not migrate to MUI v.5
 * it is simpler just to import theme directly where we need it.
 */
const cssTheme: CssTheme = {
  typography: typographyTheme,

  link: {
    'text-decoration': 'none'
  },

  ...VehicleCardThemes,
  ...DealerInfoThemes,
  ...FooterInfoThemes,
  ...ScheduleThemes,
  ...SocialsThemes,
  ...DealerLocationsPopoverThemes,
  ...VehiclesFilterThemes,
  ...VehiclesSortingThemes,
  ...VehiclesFilterChipsThemes,
  ...InventoryPageThemes,
  ...VehicleCardSkeletonThemes,
  ...VehicleBriefInfo,
  ...Header,
  ...PrequalifiedFormSSNIncome,
  ...InfoLink,
  ...UnsubscribePage,
  ...UnsubscribedPage,
  ...DealershipLocationInfo,
  ...DealershipLocations,
  ...DealerLocationsMenu,
  ...HomePage,

  // ============ COMPONENTS ============= /
  GeneralLayout: {
    default: {
      content: `
        background-color: var(--mainBackgroundColor);
        font-family: Roboto, sans-serif;

        p {
          margin: 0px;
        }

        h1 {
          ${typographyTheme.h1}
        }
        
        h3 {
          ${typographyTheme.h3}
        }

        p.small-text {
          ${typographyTheme.default}
          ${typographyTheme.smallText}
        }

        .MuiTypography-h1 {
          font-family: Roboto, sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 376px) {
            font-size: 32px;
          }

          @media (min-width: 767px) {
            font-size: 44px;
          }
        }

        .MuiTypography-h2 {
          font-family: Roboto, sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 376px) {
            font-size: 20px;
          }
          
          @media (min-width: 767px) {
            font-size: 24px;
          }
        }

        .MuiTypography-subtitile1 {
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.5;

          @media (min-width: 767px) {
            font-size: 18px;
          }
        }
      `
    }
  },
  FiltersDrawerToggler: {
    default: {
      root: `
        &.cf-button-root.cf-button-variant-text {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .cf-button-start-adornment {
            padding-right: 4px;
            height: 20px;

            * {
              width: unset;
            }
          }

          &, :hover {
            background-color: transparent;
          }

          .cf-button-content {
            font-size: 16px;
            font-weight: 500;
          }
        }
      `
    }
  },
  CarAppraisalForm: {
    default: {
      root: `
        background: #fff;
        padding: 40px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 12px;
        height: min-content;
      `,
      content: `
        display: grid;
        justify-items: center;
        grid-template-rows: 36px 32px auto;
        grid-template-areas:
          "FormControls"
          "."
          "Form"
        ;

        .form-controls > div {
          border-radius: 0px;
          border-bottom: 4px solid #F3F3F3;

          &.active, &:hover {
            border-bottom: 4px solid var(--mainColor);

            & button {
              color: var(--mainColor);
            }
          }
        }
      `
    }
  },

  DrawerMenu: {
    default: {
      root: `
        color: #000;
      `
    }
  },
  HowItWorks: {
    default: {
      root: `
        padding: 34px 4px;
        background: #fff;
        margin-bottom: 0px;

        .Text2 {
          margin: 0 40px;
        }

        .List {
          justify-self: normal;

          @media (max-width: 480px) {
            [class*=listItem] {
              margin: 0px 0px 10px;
            }
          }
        }
      `
    },
    financingPage: {
      root: `
        @media (min-width: 768px) {
          margin-bottom: 80px;
        }
      `
    }
  },

  InfoCard: {
    default: {
      root: `
        border: 1px solid #ECF1EF;
        border-radius: 20px;
        max-width: 360px;
        min-width: 220px;
        width: 340px;
        height: 236px;
        display: flex;
        box-sizing: border-box;

        > div {
          width: 85%;
        }

        @media (min-width: 480px) {
          height: 256px;
          width: 220px;

          padding: 0px 8px;
         
          > div {
            width: 100%;
          }
        }

        @media (min-width: 1200px) {
          height: 344px;
          width: 360px;
        }

        .ExtendedImage {
          margin-bottom: 8px;
          width: 80px;
          height: 80px;

          @media (min-width: 768px) {
            margin-bottom: 16px;
          }
        }

        .Title, .Subtitle, .Text {
          margin-top: 8px;
          text-align: center;
        }
      `,
      content: `
        align-items: center;
        width: 210px;
        margin: 12px auto 0;
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
          min-width: 340px;
          margin: auto;
        }
      `
    }
  },

  RecommendedVehicles: {
    default: {
      root: `
        --offset: max(40px, calc((100vw - 1360px) / 2));

        overflow-x: hidden;
        z-index: 2;

        .cf-loader {
          grid-column: 1 / 5;
          min-height: 500px;
        }

        &.vehicle-details-view {
          padding-top: 40px;
        }

        .component-container {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-areas: "Text" "RecommendedVehiclesList" "Button";
          grid-gap: 16px;
          position: relative;

          .Button {
            margin: -8px auto 0px;
          }

          > .Text {
            margin: 0px 16px;
          }

          @media (min-width: 768px) {
            grid-gap: 24px;
          }

          @media (min-width: 375px) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "Text Button" "RecommendedVehiclesList RecommendedVehiclesList";
            grid-gap: 16px;

            .Button {
              margin: 0px var(--offset) 0px auto;
            }

            > .Text {
              margin: 0px auto 0px var(--offset);
            }
          }

          @media (min-width: 1200px) {
            grid-gap: 32px;

            .cf-list-scroll-horz-btn {
              display: flex;

              &.right {
                margin-right: var(--offset);
              }

              &.left {
                margin-left: var(--offset);
              }
            }
          }
        }
      `,
      content: `
        grid-area: RecommendedVehiclesList;
        position: relative;
        padding: 0 40px;

        ${horizontallyScrollable}
        
        grid-column: 1 / 3;
        display: grid;
        grid-template-columns: repeat(4, 328px);
        grid-column-gap: 16px;
        align-items: center;

        @media (max-width: 375px) {
          padding: 0 16px 10px;
        }

        @media (min-width: 768px) {
          padding: 0px max(40px, calc((100vw - 1360px) / 2));
        }
      `
    },

    vehicleInfo: {
      root: `
        width:100%;
        background: var(--mainBackgroundColor);
        overflow: hidden;
        margin-left: min(-16px, calc(-1 * (100vw - 343px) / 2));
        margin-right: min(-16px, calc(-1 * (100vw - 343px) / 2));

        @media (min-width: 768px) {
          margin-left: min(-40px, calc(-1 * (100vw - 1360px) / 2));
          margin-right: min(-40px, calc(-1 * (100vw - 1360px) / 2));
        }
      `,
      content: horizontallyScrollable
    }
  },

  NotFound: {
    default: {
      root: `
        & .Button.back-btn {
          min-width: 290px;
        }
      `
    }
  },

  LazyImage: {
    default: {
      root: `
        flex: 1;
        max-width: 100%;
        max-height: 100%;
        position: relative;

        &.Placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 24px;

          & img {
            max-height: 72px;
            max-width: 216px;
          }
        }

        & > [class*=Skeleton] {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          transform: unset;
        }
      `
    }
  },

  Button: {
    default: {},
    text: {
      root: ButtonText({ textAlign: 'left' })
    },
    textRight: {
      root: ButtonText({ textAlign: 'right' })
    },
    textWithConfig: {
      root: ButtonText
    },
    textTabStyled: {
      root: ButtonText({
        textAlign: 'center',
        fontWeight: 500,
        color: '#333'
      })
    },
    outlinedSearch: {
      root: `
        .MuiButton-root {
          max-height: 56px;
          min-height: 56px;
          border-radius: 12px;
          border-color: var(--mainOutlinedButtonColor);
          color: var(--mainOutlinedButtonColor);
        }
      `
    },
    back: {
      root: `
        .MuiButtonBase-root {
          padding: 0;
          border-radius: 12px;
          background: #fff;
          box-shadow: none;
          text-transform: capitalize;
          text-decoration: underline;
          font-weight: 700;
          font-size: 16px;
          box-shadow: none;
          
          :hover {
            background: #fff;
            box-shadow: none;
          }
        }
      `
    },
    search: {
      root: `
        & .MuiButton-root {
          max-height: 48px;
          min-height: 48px;
          box-sizing: border-box;
          border-radius: 12px;
          background-color: #000;
          color: #fff;

          &:hover {
            background-color: #212121;
          }

          &.Mui-disabled {
            background-color: var(--buttonDisabledColor);
          }
        }
      `
    },
    showMore: {
      root: `
        & .MuiButton-root {
          max-height: 36px;
          min-height: 36px;
          box-sizing: border-box;
          border-radius: 8px;
          border: 1px solid #DBDBDB;
          background-color: #fff;
          text-transform: none;
          box-shadow: none;
          padding: 13px 8px;
          color: #101010;
          font-size: 14px;

          &:hover {
            box-shadow: none;
            background-color: #fff;
          }
        }
      `
    },
    filters: {
      root: `
        & .MuiButton-root {
          background-color: transparent;
          color: #000;

          & .MuiButton-label {
            text-transform: capitalize;
          }
        }
      `
    },
    icon: {
      root: `
        & .MuiButtonBase-root.MuiIconButton-root {
          padding: 0;
        }
      `
    }
  },

  VehiclesSearch: {
    default: {
      content: `
        .cf-input-container {
          border: 1px solid #DBDBDB;
          background: #fff;
          border-radius: 12px;
          color: rgba(33, 33, 33, 0.8);
        }
      `
    },
    medium: {
      content: `
        & .MuiTextField-root {
          max-height: 36px;
          min-height: 36px;
        }
      `
    }
  },

  AboutUsPromos: {
    default: {
      root: `
        background-color: var(--mainBackgroundColor);
      `
    }
  },

  AboutUsPage: {
    default: {
      root: `
        .AboutUsPromos, .DealerInfo {
          background-color: #fff;
        }
      `
    }
  },

  Footer: {
    default: {
      root: `
        z-index:2;
        background-color: #fff;
        
        @media(min-width: 768px) {
          .DealerInfoFragment {
            display: flex;
            align-items: center;
            
            a.phone-block {
              margin: 0;
              margin-right: 16px;
            }
          }
        }
      `
    }
  },

  VehicleViewContent: {
    default: {
      root: `
        background: white;
        strong { font-weight: bold; }
        white-space: pre-wrap;
        
        .no-image-wrapper {
          background: #F6F9F8;
          border-radius: 8px;
          img {
            width: 120px;
          }
        }
        
        .VehicleDescriptionLabel {
          margin-top: 24px;
          margin-bottom: 16px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
        }

        .InfoLinkBlock {
          display: flex!important;
          flex-direction: column;
          row-gap: 16px;
          
          @media screen and (min-width: 1024px) {
            margin-top: 12px;
          }

          @media screen and (min-width: 768px) {
            display: grid!important;
            grid-template-columns: 1fr 1fr!important;
            column-gap: 16px!important;
          }
        }
        
        .IncludedWithCarAds {
          margin-top: 24px;

          @media screen and (min-width: 1024px) {
            margin-top: 32px;
          }
        }
        
        .TestDriveBlock {
          border-radius: 12px;
          background: #F4FCFA;
          padding: 24px;
          position: relative;
          margin-top: 24px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
          
          .test-drive-title {
            color: #101010;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
            margin-bottom: 4px;
          }
          
          .test-drive-content {
            color: rgba(33, 33, 33, 0.50);
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            margin-bottom: 16px;
          }
          
          .test-drive-button {
            .MuiButtonBase-root {
              box-shadow: none;
              border-radius: 12px;
              background: var(--mainColor);
              color: #ffffff;
              max-width: 213px;
              
              .MuiButton-label {
                font-weight: 500;
                line-height: 24px;
                display: flex;
                align-items: center;
              }
            }
          }
          
          img {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
          }
          
          @media screen and (min-width: 768px) {
            padding-right: 110px;
            img {
              display: block;
            }
          }
        }
        
        .IncludedWithCarAdsContent {
          .Ad1, .Ad2,.Ad3 {
            > div {
              border-radius: 8px;

              p {
                letter-spacing: -0.24px;
              }
            }
          }
        }

        .VehicleDescriptionContent {
          @media (min-width: 1440px) {
            margin-bottom: 0;
          }
          
          .show-more-less-block {
            justify-content: flex-start;
            
            button {
              color: #212121;
              font-size: 18px;
              font-style: normal;
              font-family: Roboto;
              font-weight: 700;
              line-height: 24px;
              margin-top: 8px;
              text-underline-position: under;
            }
          }

          > div {
            @media (max-width: 1440px) {
              word-break: break-word;
            }
          }
        }
        
        .SalePrice {
          > div {
            p:last-child {
              border-left: 1px solid rgba(33, 33, 33, 0.5);
              padding-left: 5px;
              color: rgba(33, 33, 33, 0.5);
              line-height: 20px;
            }
          }
        }
        
        .VehicleSpecsOverview {
          margin-top: 24px;
          
          @media screen and (min-width: 1200px) {
            margin-top: 32px;
          }
          
          .Cylinders {
              > div {
                border-bottom: none;
              }
            }
          }
          
          @media screen and (min-width: 768px) {
            .BodyType {
              > div {
                border-bottom: none;
              }
            }
          }
          
          .Title {
            h2 {
              font-size: 20px;
            }
          }
        }
        
        .FeatureOptionListTitle {
          h2 {
            font-size: 20px;
          }
        }
        
        .VehicleDescriptionLabel {
          h2 {
            font-size: 20px;
          }
        }
      `
    }
  },

  GalleryVehicleView: {
    default: {
      root: `
        height: 100%;
      `,
      content: `
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      `
    }
  },

  GetPrequalifiedComponent: {
    default: {
      root: `
        &.financing-get-prequalified {
            .MuiButtonBase-root {
              background: var(--mainOutlinedButtonColor);
              max-height: 56px;
              height: 56px;

              :hover {
                background: var(--mainOutlinedButtonColor);
              }
            }
        }

        .GetPrequalifiedButton {
          max-width: 225px;
          
          button {
            font-weight: 400;
          }
        }
      `
    }
  },

  GetPrequalifiedCallToAction: {
    default: {
      content: `
        & .Text1 p, & .Text1 span, & .Text2 p, & .Text2 span {
          text-align: center;

          @media (min-width: 768px) {
            text-align: left;
          }
        }
      `
    }
  },

  TradeSellIntro: {
    default: {
      content: `
        & .Text1 p, & .Text1 span, & .Text2 p, & .Text2 span {
          text-align: center;

          @media (min-width: 1200px) {
            text-align: left;
          }
        }
      `
    }
  },

  RequestHelpOrDrive: {
    default: {
      root: `
        .RequestHelpOrDriveLink {
          display: flex;
          align-items: center;
          padding: 12px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 12px;

          & .MuiButton-root {
            min-width: 40px;
          }

          & .MuiButton-label {
            padding-left: 8px;
            font-weight: 500;
          }
        }
      `
    }
  },

  VehicleServiceRequestModalForm: {
    default: {
      root: `
      --form-row-gap: 28px;

      & {
        padding-top: 32px;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 12px;
        grid-row-gap: var(--form-row-gap);

        @media (max-width: 680px) {
          padding-top: 0;
        }

        & .form-field {
          @media (max-width: 680px) {
            grid-column-start: 1;
            grid-column-end: span 2;
          }
        }

        & .form-field.Comments {
          grid-column-start: 1;
          grid-column-end: span 2;

          & .MuiFormControl-root {
            max-height: 102px;
          }

          & .MuiInputBase-root.MuiFilledInput-root {
            min-height: unset;
            max-height: unset;
            background: #fafafa;
          }
        }

        & .disclosure-block {
          grid-column-start: 1;
          grid-column-end: span 2;
          
          a, p {
            color: rgba(33, 33, 33, 0.50);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }

        & .form-button {
          margin-top: calc(48px - var(--form-row-gap));

          @media (max-width: 680px) {
            margin-top: calc(66px - var(--form-row-gap));
          }
          
          @media (max-width: 480px) {
            margin-top: 0;
            margin-bottom: var(--form-row-gap);
          }
        }

        & .MuiButtonBase-root {
          height: 56px;
          min-height: 56px;
          border-radius: 12px;
          box-shadow: none;
        }
      }
    `
    }
  },
  GetPrequalifiedForm: {
    default: {
      root: `
        max-width: 1008px;
        margin: 0 auto;
        padding: 24px 0;
        background: #ffffff;
        
        @media (min-width: 768px) {
          padding: 44px;
        }
        
        .form-container {
          max-width: 600px;
          margin: 0 auto;

          @media (max-width: 768px) {
            padding: 0 16px;
          }
          
          .cf-form-container-personal {
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 12px;
            grid-row-gap: 32px;
            
            .form-field {
              @media (max-width: 680px) {
                grid-column-start: 1;
                grid-column-end: span 2;
              }
              
              &.cf-full-width {
                grid-column-start: 1;
                grid-column-end: span 2;
              }
            }
          }
          
          
          .prequalify-form-reminder-reminder {
            margin: 32px 0;

            a, p {
              color: rgba(33, 33, 33, 0.50);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.25px;
            }
          }
          
          .cf-form-head {
            margin-bottom: 16px;

            h5 {
              font-weight: 500;
              font-size: 24px;
              line-height: 24px;
              color: #101010;
            }

            &.cf-personal-info {
              margin-bottom: 32px;
            }
          }
          
          .MuiButtonBase-root {
            max-height: 56px;
            height: 56px;
            border-radius: 12px;
            box-shadow: none;
          }
          
          .Button {
            .MuiButtonBase-root {
              background: var(--mainOutlinedButtonColor);
            }
          }
        }
      `
    }
  },
  GetPrequalifiedSuccess: {
    default: {
      root: `
        .cf-main-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 16px;
          border-bottom: 1px solid #D9D9D9;
        }
        
        .cf-split-blocks {
          display: flex;
          justify-content: center;
          padding-top: 16px;
          
          .cf-split-block {
            display: flex;
            align-items: center;
            padding: 0 32px;
            
            .cf-image-wrapper {
              margin-right: 10px;
              max-height: 28px;
            }
            
            &:first-child {
              border-right: 1px solid #D9D9D9;
            }
          }
        }
        
        .Button {
          margin: 0px 16px;

          .MuiButtonBase-root {
            max-height: 56px;
            margin-top: 40px;
            height: 56px;
            border-radius: 12px;
            background: var(--mainOutlinedButtonColor);
            
            :hover {
              background: var(--mainOutlinedButtonColor);
            }
          }
        }
      `
    }
  },
  PrequalifiedFormError: {
    default: {
      root: `
      padding: 40px 16px;
      background: #fff;
      margin-bottom: 40px;

      .ButtonGoShopWrapper .MuiButton-root {
        max-height: 56px;
        height: 56px;
        background: var(--mainOutlinedButtonColor);
        
        :hover {
          background: var(--mainOutlinedButtonColor);
        }
      }
      
      .ErrorHead {
        h1 {
          font-weight: 500;
        }
      }
      
      .ErrorSubHead {
        p {
          font-size: 14px;
        }
      }
      `
    }
  },
  GetPrequalifiedFormPage: {
    default: {
      root: `
      .TextGetPrequalifiedFormPageHeader1 {
        h1 {
          font-weight: 500;
        }
      }
    `
    }
  }
}

export default cssTheme
